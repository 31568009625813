@import url("../../../themes/Colors.css");

.checkbox-button-hidden {
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox-button {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: 125px;
  padding: 5px;
  height: 30px;
  line-height: 40px;
  background: var(--snow);
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.checkbox-button-checked {
  border: 1px solid var(--primary);
  transition: background-color 0.4s;
  background: var(--extraLightPrimary);
  box-shadow: 0 0 4px 0 rgba(0, 71, 190, 0.2);
}

.checkbox-icon {
  padding: 0px 5px 0px 0px;

}
