:root,
:root.latinad {
  /* App Theme / Brand */
  --background: #fafafa;
  --primary: #3996f3;
  --blueDark: #163B78;
  --lightPrimary: #3996f380;
  --extraLightPrimary: #e6f7ff;
  --secondary: #1565C0;
  --backgroundPrimary: #1e88e5;
  --backgroundSecondary: #42a5f5;
  --green: #3bbfa4;
  --red: #f44336;
  --blue: #295fd2;
  --gray: #f1f2f2;
  --grayMedium: #b1b1b1;
  --black: #000;
  --snow: #FFF;
  --snowSecondary: #FFF;
  --coal: #424242;
  --transparent: #00000000;
  --atention: #fa8c16;
  --backgroundImageLogin: url("../images/bg3.webp")
}

:root.grupoVia {
  /* App Theme / Brand */
  --background: #fff;
  --primary: #f4793b;
  --blueDark: #fff;
  --lightPrimary: #ffb992;
  --extraLightPrimary: #fed4bd69;
  --secondary: #b93f02;
  --backgroundPrimary: #fff;
  --backgroundSecondary: #fff;
  --green: #3bbfa4;
  --red: #f44336;
  --blue: #295fd2;
  --gray: #f1f2f2;
  --grayMedium: #b1b1b1;
  --black: #000;
  --snow: #FFF;
  --snowSecondary: #FFF;
  --coal: #424242;
  --transparent: #00000000;
  --atention: #fa8c16;
  --primaryPins: #f4793b;
  --lightPrimaryPins: #ffb992;
  --backgroundImageLogin: url("../images/bg3.webp")
}

:root.mediaShake {
  /* App Theme / Brand */
  --background: #fff;
  --primary: #f4793b;
  --blueDark: #fff;
  --lightPrimary: #ffb992;
  --extraLightPrimary: #fed4bd69;
  --secondary: #b93f02;
  --backgroundPrimary: #fff;
  --backgroundSecondary: #fff;
  --green: #3bbfa4;
  --red: #f44336;
  --blue: #295fd2;
  --gray: #f1f2f2;
  --grayMedium: #b1b1b1;
  --black: #000;
  --snow: #FFF;
  --snowSecondary: #FFF;
  --coal: #424242;
  --transparent: #00000000;
  --atention: #fa8c16;
  --primaryPins: #ff7200;
  --lightPrimaryPins: #ff7200b9;
  --backgroundImageLogin: url("../images/bg3.webp")
}
:root.smartFit {
  /* App Theme / Brand */
  --background: #fff;
  --primary: #ffb612;
  --blueDark: #fff;
  --lightPrimary: #0000001f;
  --extraLightPrimary: #7e7b7b39;
  --secondary: #000;
  --backgroundPrimary: #ffb8127a;
  --backgroundSecondary: #00000099;
  --green: #3bbfa4;
  --red: #f44336;
  --blue: #295fd2;
  --gray: #f1f2f2;
  --grayMedium: #b1b1b1;
  --black: #000;
  --snow: #FFF;
  --snowSecondary: #FFF;
  --coal: #424242;
  --transparent: #00000000;
  --atention: #ed3211;
  --primaryPins: #ffb612;
  --lightPrimaryPins: #ffb812a7;
  --backgroundImageLogin: url("../images/bg3.webp")
}
:root.duoPrint {
  /* App Theme / Brand */
  --background: #fff;
  --primary: #c11911;
  --blueDark: #fff;
  --lightPrimary: #0000001f;
  --extraLightPrimary: #00000042;
  --secondary: #0000;
  --backgroundPrimary: #fff;
  --backgroundSecondary: #fff;
  --green: #3bbfa4;
  --red: #f44336;
  --blue: #295fd2;
  --gray: #f1f2f2;
  --grayMedium: #b1b1b1;
  --black: #000;
  --snow: #FFF;
  --snowSecondary: #FFF;
  --coal: #424242;
  --transparent: #00000000;
  --atention: #fa8c16;
  --primaryPins: #c11911;
  --lightPrimaryPins: #c11a11a5;
  --backgroundImageLogin: url("../images/bg3.webp")
}
:root.territorioDigital {
  /* App Theme / Brand */
  --background: #fafafa;
  --primary: #F4A703;
  --blueDark: #163B78;
  --lightPrimary: #F4A70380;
  --extraLightPrimary: #f4a8032a;
  --secondary: #3D6D8C;
  --backgroundPrimary: #F4A703;
  --backgroundSecondary: #f4a8031b;
  --green: #3bbfa4;
  --red: #f44336;
  --blue: #295fd2;
  --gray: #f1f2f2;
  --grayMedium: #b1b1b1;
  --black: #000;
  --snow: #FFF;
  --snowSecondary: #3D6D8C;
  --coal: #424242;
  --transparent: #00000000;
  --atention: #fa8c16;
  --primaryPins: #F4A703;
  --lightPrimaryPins: #f4a703cd;
  --backgroundImageLogin: url("../images/bg3.webp")
}
:root.movImagen {
  /* App Theme / Brand */
  --background: #fafafa;
  --primary: #FF6900;
  --blueDark: #163B78;
  --lightPrimary: #FF690080;
  --extraLightPrimary: #FF69002a;
  --secondary: #707070;
  --backgroundPrimary: #FF6900;
  --backgroundSecondary: #FF69001b;
  --green: #3bbfa4;
  --red: #f44336;
  --blue: #295fd2;
  --gray: #f1f2f2;
  --grayMedium: #b1b1b1;
  --black: #000;
  --snow: #FFF;
  --snowSecondary: #FFF;
  --coal: #424242;
  --transparent: #00000000;
  --atention: #fa8c16;
  --primaryPins: #FF6900;
  --lightPrimaryPins: #f0983a;
  --backgroundImageLogin: url("../images/bg3.webp")
}
:root.yoda {
  --background: #fafafa;
  --primary: #ffcc00;
  --secondary: #6d6e6f;
  --blueDark: #163B78;
  --lightPrimary: #ffcc00cc;
  --extraLightPrimary: #fff4cc;

  --backgroundPrimary: #1e88e5;
  --backgroundSecondary: #42a5f5;
  --green: #3bbfa4;
  --red: #f44336;
  --blue: #295fd2;
  --gray: #f1f2f2;
  --grayMedium: #b1b1b1;
  --black: #000000;
  --snow: #ffffff;
  --snowSecondary: #ffffff;
  --coal: #424242;
  --transparent: #00000000;
  --atention: #fa8c16;
  --primaryPins: #cca300;
  --lightPrimaryPins: #ffcc00;
  --backgroundImageLogin: url("../images/bg3.webp")
}
:root.hakMedia {
  /* App Theme / Brand */
  --background: #fafafa;
  --primary: #580ECC;
  --blueDark: #2F0A66;
  --lightPrimary: #580ECC80;
  --extraLightPrimary: #E6DAFF;
  --secondary: #3D0D8C;
  --backgroundPrimary: #4A0CB0;
  --backgroundSecondary: #6A2ED6;
  --green: #3bbfa4;
  --red: #f44336;
  --blue: #4727A3;
  --gray: #f1f2f2;
  --grayMedium: #b1b1b1;
  --black: #000;
  --snow: #FFF;
  --snowSecondary: #FFF;
  --coal: #424242;
  --transparent: #00000000;
  --atention: #fa8c16;
  --primaryPins: #580ECC;
  --lightPrimaryPins: #570eccaf;
  --backgroundImageLogin: url("../images/whiteLabel/login-hak-media.png")
}