@import url("../../themes/Colors.css");

.cls-1 {
    stroke-miterlimit: 10;
}

.cls-1, .cls-2, .cls-3 {
    fill: none;
    stroke: var(--primary);
}

.cls-1, .cls-3 {
    stroke-width: 7.83px;
}

.cls-4 {
    fill: var(--primary);
}

.cls-2 {
    stroke-width: 10.44px;
}

.cls-2, .cls-3 {
    stroke-linecap: round;
    stroke-linejoin: round;
}